import { AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY, FOLDER_VIDEOS } from '../config/envVariables';
import { bucketName, programmingEndpoint, REGION_AWS } from '../services/endpoints';
import updateMovement from './updateMovement';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';

export const updateTags = async (tags) => {
    let parsedTags = tags.map((tag) => ({ id: tag.id, title: tag.text }));

    try {
      const response = await fetch(programmingEndpoint + '/tags', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parsedTags),
      });
      const responseParsed = await response.json();
      return responseParsed;
    } catch (error) {}
  };
  export const fetchMovements = async (q) => {
    const response = await fetch(programmingEndpoint + '/search?movement=' + q);

    const result = await response.json();
    return result;
  }; 
  export const updateVersion = async (
    movement_easy,
    movement_hard,
    movementValue,
    version,
    variationInput,
    variationInputHard
  ) => {

    return 
    let success;
    if (movement_easy || movement_hard) {
      if (version == 'all' && movement_easy && movement_hard) {
        ({ success } = await updateMovement(movement_easy, {
          hard_version: variationInput?.length == 0 ? null : movementValue,
        }));
        ({ success } = await updateMovement(movement_hard, {
          easy_version: variationInputHard.length == 0 ? null : movementValue,
        }));
      } else if (version == 'easy' && movement_hard) {
        ({ success } = await updateMovement(movement_hard, {
          easy_version: variationInputHard.length == 0 ? null : movementValue,
        }));
      } else if (movement_easy) {
        ({ success } = await updateMovement(movement_easy, {
          hard_version: variationInput?.length == 0 ? null : movementValue,
        }));
      }
    }

    return success;
  };

  export const handleWithFileUpload = async (file,dataMovement,updatedTags,formValues)=>{
  
    const target = {
        Bucket: bucketName,
        Key: `${FOLDER_VIDEOS}/${dataMovement?._id}.${dataMovement?.video_extension || 'mp4'}`,
        Body: file,
      };
      const credentials = {
        accessKeyId: AWS_ACCESS_KEY || '',
        secretAccessKey: AWS_SECRET_ACCESS_KEY || '',
      };
      try {
        let success;
        const parallelUploads3 = new Upload({
          client: new S3Client({ credentials, region: REGION_AWS }),
          leavePartsOnError: false,
          params: target,
        });
        const uploadFinished = await parallelUploads3.done();
        console.log('uploadFinished',uploadFinished);
        console.log('uploadFinished.$metadata.httpStatusCode',uploadFinished.$metadata.httpStatusCode);
        if (uploadFinished.$metadata.httpStatusCode !== 200) {
        //   setIsError(true);
        //   setTextError('El video no fue actualizado');
        throw new Error('El video no fue actualizado');
        } else {
          success = await updateMovement(dataMovement?._id, {
            ...formValues,
            tags: updatedTags.map((tag) => ({
              _id: tag._id,
              title: tag.title,
            })),
            // easy_version: selectedVariation?._id,
            // video_size: file.size,
   
          });
          return success
        }
      
      } catch (error) {
      throw new Error('El video no fue actualizado');
      }
  }