import React, { useEffect, useReducer } from 'react';
import AppRouter from './routers/AppRouter';
import { AuthContext } from './auth/AuthContext';
import { authReducer } from './auth/authReducer';

import './style.css';
import { programmingEndpoint } from './services/endpoints';

const init = async () => {
  let equipment = await fetch(programmingEndpoint + '/equipment')
  let trainings = await fetch(programmingEndpoint +'/trainings')
  let tags = await fetch(programmingEndpoint + '/tags')
        let plans = await fetch(`https://biggfriends.com/wp-json/programming/v1/api/plans`)
        let resultplans = await plans.json()
  let resultEquipment = await equipment.json()
  let resulttrainings = await trainings.json()


  let resultags = await tags.json()

  

  if (localStorage.getItem('user')) {
    return {user:JSON.parse(localStorage.getItem('user') || ''),equipment:resultEquipment ,tags:resultags,
    plans:Object.values(resultplans).map((plan:any)=>({id:plan.id,title:plan.title,divition:plan.divition})).filter((plan)=>!plan.title.includes('Home Edition')).sort((a:any,b:any)=>a.title > b.title ? 1 : -1),

    trainings:resulttrainings};
  }
  return { logged: false };


};

function App() {
  const [user, dispatch] = useReducer(authReducer, {}, init);

  useEffect(() => {
    if(user){
      localStorage.setItem('user', JSON.stringify(user));

    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, dispatch }}>
      <AppRouter />
    </AuthContext.Provider>
  );
}

export default App;
