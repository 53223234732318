import { useState } from 'react';

export const useForm = (initialState) => {
  const [values, setValues] = useState(initialState);

  const reset = (newState = {}) => {
    const state =
      Object.keys(newState).length === 0 && newState.constructor === Object
        ? initialState
        : newState;
    setValues(state);
  };

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };
  const handleCheckbox = ({ target, value }) => {

    // return console.log(value)
    // si `value` es un objeto, extraer el valor que necesitamos
    const newValue = typeof value === "object" ? value.value : value;
    let updateValue;
    if(!typeof value === "object" ) {
      if (values[target.name].find((val) => val === newValue)) {
        updateValue = values[target.name].filter((val) => val !== newValue);
      } else {
        updateValue = values[target.name].concat(newValue);
      }
    } else {
       if (values[target.name].find((val) => val.id ? val.id   === value.id : val == value)) {
          
        updateValue = values[target.name].filter((val) => val.id ? val.id !== value.id : val !== value);
       } else {
        console.log('concat',values[target.name].concat(value));
        
        updateValue = values[target.name].concat(value);
       }
    }
  

    setValues({
      ...values,
      [target.name]: updateValue,
    });
  };
  return [values, handleInputChange, reset,handleCheckbox];
};
