export const extractData = (arrayData: any[], index: number, itemsPerPage: number) => {
  let newArray = [...arrayData] as any;
  return newArray.splice(index, itemsPerPage);
};

export const generateButtons = (
  pagesNumber: number,
  currentPage = 1,
  itemsPerPage = 15,
  buttonsNumber = 5,
) => {
  //PAGINADOR
  // cantidadBotones -> Debe ser 3 o mayor a 3 (Estetica de botones)
  currentPage = currentPage || 1;
  pagesNumber = Math.ceil(pagesNumber / itemsPerPage);

  //Certificar que siempre sea impar
  if (buttonsNumber % 2 === 0) {
    buttonsNumber = buttonsNumber - 1;
  }

  //No pasar el limite
  if (currentPage > pagesNumber) {
    currentPage = pagesNumber;
  }

  //Posicion inicial del arreglo
  const elementStart = (currentPage - 1) * itemsPerPage;

  //Recolectar botones
  let arrayButtons = [];
  // Caso 1: La cantidad de paginas coinciden con la cantidad de botones o no necesito todos los botones
  if (pagesNumber <= buttonsNumber) {
    buttonsNumber = pagesNumber;
    for (let i = 0; i < buttonsNumber; i++) {
      arrayButtons.push(i + 1);
    }
    // Caso 2: logica para seleccionar botones
  } else {
    // Saber cuantos botones debo obtener para el Prev y para el Next
    let iterador = Math.floor(buttonsNumber / 2);

    let btnsSobranPrev = 0;
    let numberPrev = currentPage;
    // Se debe sumar UNO, porque se debe contar la pagina actual como primer elemento del arreglo
    for (let i = 0; i < iterador + 1; i++) {
      //Se agrega la pagina actual como primer boton
      if (i === 0) {
        arrayButtons.push(numberPrev);
      } else {
        numberPrev = numberPrev - 1;
        if (numberPrev > 0) {
          arrayButtons.push(numberPrev);
        } else {
          btnsSobranPrev++;
        }
      }
    }

    // Se revisa si sobraron botones del Prev para añadirlos al Next
    iterador = iterador + btnsSobranPrev;
    let btnsSobranNext = 0;
    let numberNext = currentPage;
    for (let i = 0; i < iterador; i++) {
      numberNext = numberNext + 1;
      if (numberNext > pagesNumber) {
        btnsSobranNext++;
      } else {
        arrayButtons.push(numberNext);
      }
    }

    // Se revisa si sobraron botones del Next para añadirlos al Prev que ya se habia construido
    if (btnsSobranNext) {
      for (let i = 0; i < btnsSobranNext; i++) {
        numberPrev = numberPrev - 1;
        arrayButtons.push(numberPrev);
      }
    }
  }

  // Se ordena de menor a mayor los botones obtenidos
  arrayButtons.sort(function (a, b) {
    return a - b;
  });

  return {
    arrayButtons: arrayButtons,
    currentPage: currentPage,
    elementStart: elementStart,
    itemsPerPage: itemsPerPage,
    pagesNumber: pagesNumber,
  };
};
