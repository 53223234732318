import { videosEndpoint } from '../services/endpoints';

export const createMovement = async (formValues) => {
  try {
    let serviceResponse;

    const response = await fetch(videosEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formValues),
    });
    const parsedResponse = await response.json();

    if (response.status === 201) {
      serviceResponse = { success: true, data: parsedResponse };
    } else {
      serviceResponse = { success: false, data: parsedResponse };
    }

    return serviceResponse;
  } catch (error) {
    console.error('Error creating movement: ', error);
    return {
      success: false,
      data: error,
    };
  }
};

export default createMovement;
