import { ondemandEndpoint } from '../services/endpoints';

export const deleteOnDemand = async (idOnDemand) => {
  try {
    let serviceResponse;

    const response = await fetch(`${ondemandEndpoint}/${idOnDemand}`, {
      method: 'DELETE',
    });
    const parsedResponse = await response;

    if (response.status === 204) {
      serviceResponse = { success: true, data: parsedResponse };
    } else {
      serviceResponse = { success: false, data: parsedResponse };
    }

    return serviceResponse;
  } catch (error) {
    console.error('Error deleting movement: ', error);
    return {
      success: false,
      data: error,
    };
  }
};

export default deleteOnDemand;
