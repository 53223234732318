import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Navbar } from '../components';
import {
  VideosEditScreen,
  VideosListScreen,
  VideosUploadScreen,
  VideosOnDemandScreen,
  ReportScreen,
  VideosListOnDemandScreen,
  VideosEditOnDemandScreen,
} from '../screens';

const DashboardRoutes = () => {
  return (
    <>
      <Navbar />
      <div className="container my-4">
        <Switch>
          <Route exact path="/lista" component={VideosListScreen} />
          <Route exact path="/cargar" component={VideosUploadScreen} />
          <Route exact path="/listaOnDemand" component={VideosListOnDemandScreen} />
          <Route exact path="/ondemand" component={VideosOnDemandScreen} />
          <Route exact path="/reports" component={ReportScreen} />

          <Route exact path="/editar/:movementId" component={VideosEditScreen} />
          <Route exact path="/editarOnDemand/:onDemandId" component={VideosEditOnDemandScreen} />
          <Redirect to="/lista" />
        </Switch>
      </div>
    </>
  );
};

export default DashboardRoutes;
