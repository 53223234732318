import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Ratio, Button, Modal } from 'react-bootstrap';

import { ondemandEndpoint,BUCKET_URL } from '../../services/endpoints';
import { extractData, generateButtons } from '../../services/paginator';
import { Video } from '../../interfaces';
import { Paginator } from '../../components';
import {  ONDEMAND } from '../../config/envVariables';
import { deleteOnDemand } from '../../helpers';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

const VideosListOnDemandScreen = ({ history }: any) => {
  const query = useQuery();
  const currentPage = query.get('page') || '1';
  const currentWord = query.get('q') || '';
  const [search, setSearch] = useState(currentWord);
  const [loading, setLoading] = useState(true);
  const [videoShow, setVideoShow] = useState(false);
  const [videoShowURL, setVideoShowURL] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [videosList, setVideosList] = useState<Video[]>([]);
  const [videosTable, setVideosTable] = useState<Video[]>([]);
  const [pageButtons, setPageButtons] = useState({
    arrayButtons: [0],
    elementStart: 0,
    itemsPerPage: 20,
  });

  const goToEdit = useCallback(({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    const idVideo = currentTarget.getAttribute('data-id');
    history.push(`/editarOnDemand/${idVideo}`);
  }, []);

  const handleVideoOpen = useCallback(({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    const idVideo = currentTarget.getAttribute('data-url');
    const version = getRandomInt(1, 50);
    setVideoURL(`${BUCKET_URL}/${ONDEMAND}/${idVideo}?v=${version}`);
    setVideoShow(true);
  }, []);

  const handleVideoOpenURL = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
      const URLVideo: any = currentTarget.getAttribute('data-url-2');
      const idVideo = URLVideo.split('=')[1];
      setVideoURL(`https://www.youtube.com/embed/${idVideo}`);
      setVideoShowURL(true);
    },
    [],
  );

  const handleVideoClose = useCallback(() => {
    setVideoShow(false);
    setVideoShowURL(false);
  }, []);

  const handleSearch = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (currentWord) {
      history.replace(history.location.pathname);
    }
    setSearch(target.value);
  }, []);

  const filteredArray = useCallback(() => {
    if (search.length === 0) {
      return videosList;
    }
    return videosList.filter((video) => {
      const videoTitle = video.video_title || video.title || '';
      return videoTitle.toLowerCase().includes(search.toLowerCase());
    });
  }, [videosList, search]);

  const getPaginationVideosList = useCallback(() => {
    const filteredArrayData = filteredArray();
    const generatedButtons = generateButtons(filteredArrayData.length, parseInt(currentPage));
    const { elementStart, itemsPerPage } = generatedButtons;
    setPageButtons(generatedButtons);
    setVideosTable(extractData(filteredArrayData, elementStart, itemsPerPage));
  }, [videosList, currentPage, search]);

  const getVideosList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(ondemandEndpoint);
      const parsedResponse = await response.json();

      if (response.status === 200) {
        setVideosList(parsedResponse);
      } else {
        console.error('Error getting videos on Videos List Screen');
      }
    } catch (error) {
      console.error('Error getting videos on Videos List Screen', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getVideosList();
  }, []);

  useEffect(() => {
    getPaginationVideosList();
  }, [videosList, currentPage, search, getPaginationVideosList]);

  const handleDeleteFiles = useCallback(
    async ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
      const idVideo: any = currentTarget.getAttribute('data-id');
      await deleteOnDemand(idVideo);
      getVideosList();
    },
    [],
  );

  return (
    <>
      {loading ? (
        <div className="alert alert-info" role="alert">
          Cargando...
        </div>
      ) : (
        <>
          <div className="mb-3 row">
            <label className="col-sm-1 col-form-label">
              <b>Buscador</b>
            </label>
            <div className="col-sm-11">
              <input type="text" className="form-control" value={search} onChange={handleSearch} />
            </div>
          </div>
          <table className="table table-striped">
            <thead className="bg-bigg bg-black color-bigg color-white">
              <tr className="text-center">
                <th scope="col">Nombre clase</th>
                <th scope="col">Instructor</th>
                <th scope="col">Sede</th>
                <th scope="col">Fecha</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {videosTable.map((video) => (
                <>
                  <tr className="text-center" key={video._id}>
                    <th scope="row" className="text-start">
                      {video.video_title ? video.video_title : video.title}
                    </th>
                    <td>{video.instructor || ''}</td>
                    <td>{video.sede || ''}</td>
                    <td>{video.date.split('T')[0]}</td>
                    <td align="center">
                      {!video.video_size ? (
                        <Button
                          className="me-2 btn-bigg btn-green"
                          data-url-2={video.url}
                          onClick={handleVideoOpenURL}
                        >
                          Ver
                        </Button>
                      ) : (
                        <Button
                          className="me-2 btn-bigg btn-green"
                          data-url={`${video._id}.${video.video_extension || 'mp4'}`}
                          onClick={handleVideoOpen}
                        >
                          Ver
                        </Button>
                      )}
                      <Button className="me-2 btn-bigg" data-id={video._id} onClick={goToEdit}>
                        Editar
                      </Button>
                      <Button variant="danger" data-id={video._id} onClick={handleDeleteFiles}>
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <Paginator query={''} path="/listaOnDemand" {...pageButtons} />
          <Modal show={videoShowURL} onHide={handleVideoClose}>
            <Modal.Header closeButton>
              <Modal.Title>Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Ratio>
                <iframe
                  id="player"
                  style={{ width: '100%', height: '100%' }}
                  src={videoURL}
                  frameBorder="0"
                ></iframe>
              </Ratio>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-bigg btn-green" onClick={handleVideoClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={videoShow} onHide={handleVideoClose}>
            <Modal.Header closeButton>
              <Modal.Title>Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Ratio>
                <video controls loop autoPlay>
                  <source src={videoURL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Ratio>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-bigg btn-green" onClick={handleVideoClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default VideosListOnDemandScreen;
