import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import {
  AWS_ACCESS_KEY,

  
  AWS_SECRET_ACCESS_KEY,
  ONDEMAND,
} from '../../config/envVariables';
import { useForm } from '../../hooks/useForm';
import { createOnDemand, updateOnDemand } from '../../helpers';
import  {bucketName,REGION_AWS} from '../../services/endpoints'

import './style.css';

const VideosOnDemand = () => {
  //const [file , setFile] = useState<File>();
  const [isCorrect, setIsCorrect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textError, setTextError] = useState('');
  const [isError, setIsError] = useState(false);
  const [formValues, handleInputChange, resetForm] = useForm({
    title: '',
    date: '',
    instructor: '',
    url: '',
    sede: '',
  });
  const { title, date, instructor, url, sede } = formValues;

  const isFormCompleted = () => {
    for (const field in formValues) {
      if (!formValues[field]) {
        return false;
      }
    }
    return true;
  };
  /*
  ///////SE DESCOMENTARA EN CASO QUE SE REQUIERA SUBIR  VIDEO FILES
  const handleFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = target.files;
    if (files) {
      setFile(files[0]);
    }
    setLoading(false);
  };
*/
  const handleUploadFiles = async () => {
    setIsError(false);
    if (!isFormCompleted()) {
      setIsError(true);
      setTextError('Hay campos vacío o sin seleccionar');
      return;
    }
    /*
    ///////SE DESCOMENTARA EN CASO QUE SE REQUIERA SUBIR  VIDEO FILES
    if (file) {
      setLoading(true);
      const { success, data } = await createOnDemand(formValues);
      if (!success || !data._id) {
        setIsError(true);
        setTextError('El video no fue subido');
        return;
      }

      const videoExtension = file.name.split('.').pop();
      const target = {
        Bucket: AWS_BUCKET_NAME,
        Key: `${ONDEMAND}/${data._id}.${videoExtension}`,
        Body: file,
      };
      const credentials = {
        accessKeyId: AWS_ACCESS_KEY || '',
        secretAccessKey: AWS_SECRET_ACCESS_KEY || '',
      };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ credentials, region: AWS_BUCKET_REGION }),
          leavePartsOnError: false,
          params: target,
        });
        const uploadFinished = await parallelUploads3.done();
        if (uploadFinished.$metadata.httpStatusCode !== 200) {
          setIsError(true);
          setTextError('El video no fue subido');
        } else {
          await updateOnDemand(data._id, {
            ...formValues,
            video_extension: videoExtension,
            video_size: file.size,
          });
          setIsCorrect(true);
        }
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
      resetForm();
    } else {
      */
    setLoading(true);
    const { success, data } = await createOnDemand(formValues);
    if (!success || !data._id) {
      setIsError(true);
      setTextError('El video no fue subido');
      return;
    }
    const target = {
      Bucket: bucketName,
      Key: `${ONDEMAND}/${data._id}`,
    };
    const credentials = {
      accessKeyId: AWS_ACCESS_KEY || '',
      secretAccessKey: AWS_SECRET_ACCESS_KEY || '',
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ credentials, region: REGION_AWS }),
        leavePartsOnError: false,
        params: target,
      });
      const uploadFinished = await parallelUploads3.done();
      if (uploadFinished.$metadata.httpStatusCode !== 200) {
        setIsError(true);
        setTextError('El video no fue subido');
      } else {
        await updateOnDemand(data._id, {
          ...formValues,
        });
        setIsCorrect(true);
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
    resetForm();
  };

  return (
    <>
      {isError && (
        <div className="alert mt-3 alert-danger" role="alert">
          <b>{textError}</b>
        </div>
      )}
      <h4>Cargar clases OnDemand</h4>
      <form>
        <div className="row mb-3">
          <div className="col">
            <label className="form-label">Nombre clase</label>
            <input
              type="email"
              className="form-control"
              name="title"
              value={title}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {/*
        <div className="row mb-4">
          <div className="col">
            <label className="form-label">instructor</label>
            <select
              className="form-select"
              name="instructor"
              value={instructor}
              onChange={handleInputChange}
            >
              <option value=""></option>
              <option value="instructor 1">instructor 1</option>
              <option value="instructor 2">instructor 2</option>
              <option value="instructor 3">instructor 3</option>
              <option value="instructor 4">instructor 4</option>
            </select>
          </div>
        </div>
        */}
        <div className="row mb-3">
          <div className="col">
            <label className="form-label">Instructor</label>
            <input
              type="email"
              className="form-control"
              name="instructor"
              value={instructor}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* SE COMENTA SELECTOR DE SEDES HASTA TENER LISTADO
        <div className="row mb-4">
          <div className="col">
            <label className="form-label">sede</label>
            <select className="form-select" name="sede" value={sede} onChange={handleInputChange}>
              <option value=""></option>
              <option value="sede 1">sede 1</option>
              <option value="sede 2">sede 2</option>
              <option value="sede 3">sede 3</option>
              <option value="sede 4">sede 4</option>
            </select>
          </div>
        </div>
        */}
        <div className="row mb-3">
          <div className="col">
            <label className="form-label">Sede</label>
            <input
              type="email"
              className="form-control"
              name="sede"
              value={sede}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="start">Fecha</label>
            <br />

            <input type="date" name="date" value={date} onChange={handleInputChange} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label className="form-label">URL a video</label>
            <input
              type="email"
              className="form-control"
              name="url"
              value={url}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {/*
        ///////SE DESCOMENTARA EN CASO QUE SE REQUIERA SUBIR  VIDEO FILES
        <div className="row">
          <div className="mb-3">
            <label className="form-label">Cargar video</label>
            <input className="form-control" type="file" name="file" onChange={handleFileChange} />
          </div>
        </div>
      */}
      </form>
      {isCorrect ? (
        <div className="alert mt-3 alert-success" role="alert">
          <b>Los datos fueron guardados correctamente</b>
        </div>
      ) : loading ? (
        <div className="alert alert-info" role="alert">
          Cargando...
        </div>
      ) : (
        !isError && (
          <div className="text-center">
            <Button className="mt-3 btn-bigg btn-green" onClick={handleUploadFiles}>
              Guardar
            </Button>
          </div>
        )
      )}
    </>
  );
};

export default VideosOnDemand;
