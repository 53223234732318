import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import { AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY, FOLDER_VIDEOS } from '../../config/envVariables';
import { useContext } from 'react';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
// import resizebase64 from 'resize-base64';
import Resizer from 'react-image-file-resizer';

import { useForm } from '../../hooks/useForm';
import { createMovement, updateMovement } from '../../helpers';
import { bucketName, REGION_AWS } from '../../services/endpoints';
import { AuthContext } from '../../auth/AuthContext';
import { WithContext as ReactTags } from 'react-tag-input';

import './style.css';
import { Video, Tag } from '../../interfaces';
import { Buffer } from 'buffer';
import { fetchMovements, updateTags, updateVersion } from '../../helpers/handleMovements';
const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const VideosUploadScreen = () => {
  const { user } = useContext(AuthContext);

  const [file, setFile] = useState<File>();
  const [isCorrect, setIsCorrect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textError, setTextError] = useState('');
  const [isError, setIsError] = useState(false);
  const [suggestions, setsuggestions] = useState([]);
  const [selectedVariation, setselectedVariation] = useState<Video>();
  const [selectedVariationHard, setselectedVariationHard] = useState<Video>();
  const [variationInputHard, setvariationInputHard] = useState('');
  const [suggestionsHard, setsuggestionsHard] = useState([]);
  const [closeSuggestionsHard, setcloseSuggestionsHard] = useState(true);
  const [variationInput, setvariationInput] = useState('');
  const [closeSuggestions, setcloseSuggestions] = useState(true);
  const inputVariation = useRef<HTMLInputElement>(null);
  const inputVariationHard = useRef<HTMLInputElement>(null);
  const [tags, setTags] = React.useState<Tag[]>([]);

  const [formValues, handleInputChange, resetForm, handleCheckbox] = useForm({
    title: '',
    category: [],
    estimulo: [],
    equipamiento: [],
    position: [],
    trainings: [],
    plans: [],
    easy_version: null,
    hard_version: null,
    tags: [],
  });
  const { title } = formValues;

  const suggestions1 = user.tags.map((tag: { id: any; title: any }) => {
    return {
      id: tag.id,
      text: tag.title,
    };
  });
  const isFormCompleted = () => {
    for (const field in formValues) {
      if (!formValues[field] && field !== 'easy_version' && field !== 'hard_version') {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (typeof variationInput == 'string' && variationInput.length > 2) {
      fetchMovements(variationInput).then((movements) => {
        console.log('movement', movements);
        setsuggestions(movements);
      });
    }
    if (variationInput.length == 0) {
      handleInputChange({ target: { name: 'easy_version', value: null } });
    }
  }, [variationInput]);

  useEffect(() => {
    if (typeof variationInputHard == 'string' && variationInputHard.length > 2) {
      fetchMovements(variationInputHard).then((movements) => {
        setsuggestionsHard(movements);
      });
    }
    if (variationInputHard.length == 0) {
      handleInputChange({ target: { name: 'hard_version', value: null } });
    }
  }, [variationInputHard]);
  const handleFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = target.files;
    if (files) {
      setFile(files[0]);
    }
    setLoading(false);
  };

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const handleUploadFiles = async () => {
    setIsError(false);
    if (!isFormCompleted()) {
      setIsError(true);
      setTextError('Hay campos vacío o sin seleccionar');
      return;
    }

    if (!file) {
      setIsError(true);
      setTextError('Falta seleccionar un video');
      setTimeout(() => {
        setIsError(false);
        setTextError('');
      }, 3300);
      return;
    }

    setLoading(true);
    const { success, data } = await createMovement(formValues);
    let updatedTags = await updateTags(tags);

    if (!success || !data._id) {
      setIsError(true);
      setTextError('El video no fue subido');
      return;
    }
    const videoExtension = file.name.split('.').pop();
    const target = {
      Bucket: bucketName,
      Key: `${FOLDER_VIDEOS}/${data._id}.${videoExtension}`,
      Body: file,
    };
    const credentials = {
      accessKeyId: AWS_ACCESS_KEY || '',
      secretAccessKey: AWS_SECRET_ACCESS_KEY || '',
    };
    console.log('credentials', credentials);

    let response = await generateVideoThumbnails(file, 0, 'file')
      .then(async (thumbnailArray) => {
        try {
          Resizer.imageFileResizer(
            dataURLtoFile(thumbnailArray[0], `${data._id}.jpeg`),
            90,
            90,
            'JPEG',
            100,
            0,
            async (uri) => {
              var buf = Buffer.from(
                uri.toString().replace(/^data:image\/\w+;base64,/, ''),
                'base64',
              );
              const parallelUploads31 = new Upload({
                client: new S3Client({ credentials, region: REGION_AWS }),
                leavePartsOnError: false,
                params: {
                  Bucket: bucketName,
                  Key: `videoThumbs/${data._id}.jpeg`,
                  Body: buf,
                  ContentEncoding: 'base64',
                  ContentType: 'image/jpeg',
                },
              });
              try {
                const uploadFinished = await parallelUploads31.done();
                console.log('uploadFinished', uploadFinished);
                return uploadFinished;
              } catch (error) {
                console.error('Error uploading thumbnail:', error);
                throw error;
              }
            },
            'base64',
          );
        } catch (err) {
          console.log('err', err);
        }
      })
      .catch((err) => {
        console.error('err', err);
      });
    console.log('response', response);

    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ credentials, region: REGION_AWS }),
        leavePartsOnError: false,
        params: target,
      });
      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log(progress);
      });
      const uploadFinished = await parallelUploads3.done();

      if (uploadFinished.$metadata.httpStatusCode !== 200) {
        setIsError(true);
        setTextError('El video no fue subido');
      } else {
        await updateMovement(data._id, {
          ...formValues,
          tags: updatedTags.map((tag: { _id: any; title: any }) => ({
            _id: tag._id,
            title: tag.title,
          })),
          video_size: file.size,
        });
        await updateVersion(
          selectedVariation?._id,
          selectedVariationHard?._id,
          data._id,
          selectedVariation && selectedVariationHard ? 'all' : selectedVariation ? 'hard' : 'easy',
          variationInput,
          variationInputHard,
        );

        setIsCorrect(true);
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
    resetForm({
      title: '',
      tags: [],
      equipamiento: [],

      category: [],
      trainings: [],
      position: [],
      easy_version: null,
      hard_version: null,
    });
    setFile(undefined);
  };
  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  useEffect(() => {
    if (isCorrect) {
      setTimeout(() => {
        setIsCorrect(false);
      }, 2000);
    }
  }, [isCorrect]);

  const handleAddition = (tag: { id: string; text: string }) => {
    if (tags) {
      setTags([...tags, tag]);
    }
    // }
  };

  const handleTagClick = (index: number) => {
    console.log('The tag at index ' + index + ' was clicked');
  };


  return (
    <>
      {isError && (
        <div className="alert mt-3 alert-danger" role="alert">
          <b>{textError}</b>
        </div>
      )}
      <h4>Crear movimiento</h4>
      <form>
        <div className="row mb-3 mt-3">
          <div className="col">
            <label className="form-label">Nombre del Movimiento</label>
            <input
              type="email"
              className="form-control"
              name="title"
              value={title}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <label className="form-label text-bold">Categoría</label>
            {['HOME', 'EQUIPPED', 'OUTDOOR', 'AT GYM'].map((category) => (
              <div key={category}>
                <input
                  // className="form-select"
                  style={{ marginRight: 10 }}
                  name="category"
                  checked={formValues.category.find((value: string) => value == category)}
                  value={category}
                  onChange={(e) =>
                    handleCheckbox({ target: { name: e.target.name }, value: e.target.value })
                  }
                  type={'checkbox'}
                />
                {category}
              </div>
            ))}
          </div>
          <div className="col ">
            <label className="form-label text-bold">Estimulos</label>
            <div className="trainings-container">
              {user.trainings.map((estimulo: { title: string; id: string }) => (
                <div className="training-col" key={estimulo.id}>
                  <input
                    // className="form-select"
                    style={{ marginRight: 10 }}
                    checked={formValues.trainings.find(
                      (value: { id: string; title: string }) => value.id == estimulo.id,
                    )}
                    name="trainings"
                    value={estimulo.id}
                    onChange={(e) => {

                      handleCheckbox({ target: { name: e.target.name }, value: { id: estimulo.id, title: estimulo.title } })
                    }}
                    type={'checkbox'}
                  />
                  {estimulo.title}
                </div>
              ))}
            </div>
          </div>
          {/* <div className="col">
            <label className="form-label">Estímulo</label>
            {['MIDLINE', 'UPPER', 'LOWER'].map((estimulo) => (
              <div key={estimulo}>
                <input
                  // className="form-select"
                  style={{ marginRight: 10 }}
                  checked={formValues.estimulo.find((value: string) => value == estimulo)}
                  name="estimulo"
                  value={estimulo}
                  onChange={handleCheckbox}
                  type={'checkbox'}
                />
                {estimulo}
              </div>
            ))}
          </div> */}
          <div className="col ">
            <label className="form-label text-bold">Equipamiento</label>
            <div className="column-list">
              {user.resultEquipment.map((equipamiento: { title: string; _id: string }) => (
                <div className="" key={equipamiento._id}>
                  <input
                    // className="form-select"
                    style={{ marginRight: 10 }}
                    name="equipamiento"
                    value={equipamiento._id}
                    checked={formValues.equipamiento.find(
                      (value: string) => value == equipamiento._id,
                    )}
                    onChange={(e) => handleCheckbox({ target: { name: e.target.name }, value: equipamiento._id })}

                    type={'checkbox'}
                  />
                  {equipamiento.title}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col ">
            <label className="form-label text-bold">Entrenamientos</label>
            <div className="trainings-container goals-container">
              {user.plans.map((plans: { title: string; id: string }) => (
                <div className="goals-col" key={plans.id}>
                  <input
                    // className="form-select"
                    style={{ marginRight: 10 }}
                    checked={formValues?.plans?.find((value: string) => value == plans.id)}
                    name="plans"
                    value={plans.id}
                    onChange={(e) =>
                      handleCheckbox({ target: { name: e.target.name }, value: plans.id })
                    }
                    type={'checkbox'}
                  />
                  {plans.title}
                </div>
              ))}
            </div>
          </div>{' '}

        </div>
        <div className="row mb-4 ">
          <div>
            <label className="form-label text-bold mt-4">Tipo</label>
            {['Parado', 'Sentado', 'Acostado'].map((type, i) => (
              <div className="" key={i}>
                <input
                  style={{ marginRight: 10 }}
                  name="position"
                  value={type}
                  checked={formValues.position.find((value: string) => value == type)}
                  onChange={(e) => handleCheckbox({ target: { name: e.target.name }, value: type })}
                  type={'checkbox'}
                />
                {type}
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label className="form-label">Variación facil</label>
            <input
              type="search"
              className="form-control"
              placeholder="Escribi un movimiento como variacion"
              name="easy_version"
              value={variationInput}
              onChange={(e) => setvariationInput(e.target.value)}
              ref={inputVariation}
              onFocus={() => setcloseSuggestions(false)}
              onBlur={() => {
                setTimeout(() => {
                  setcloseSuggestions(true);
                }, 200);
              }}
            // onChange={handleInputChange}
            />
            {suggestions.length > 0 && variationInput.length > 0 && !closeSuggestions && (
              <div className="suggestions">
                {suggestions.map((movement: Video) => (
                  <div
                    key={movement._id}
                    className="suggestions-item"
                    onClick={() => {
                      setselectedVariation(movement);
                      setcloseSuggestions(true);
                      setvariationInput(movement.title);
                      handleInputChange({ target: { name: 'easy_version', value: movement._id } });
                    }}
                  >
                    <p>{movement.title}</p>
                    {/* <span style={{fontSize:14,marginLeft:10}}>({movement._id})</span> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label className="form-label">Variación dificil</label>
            <input
              type="search"
              className="form-control"
              placeholder="Escribi un movimiento como variacion"
              name="hard_version"
              value={variationInputHard}
              onChange={(e) => setvariationInputHard(e.target.value)}
              ref={inputVariationHard}
              onFocus={() => setcloseSuggestionsHard(false)}
              onBlur={() => {
                setTimeout(() => {
                  setcloseSuggestionsHard(true);
                }, 200);
              }}
            />
            {suggestionsHard.length > 0 && variationInputHard.length > 0 && !closeSuggestionsHard && (
              <div className="suggestions">
                {suggestionsHard.map((movement: Video) => (
                  <div
                    className="suggestions-item"
                    onClick={() => {
                      setselectedVariationHard(movement);
                      setcloseSuggestionsHard(true);
                      setvariationInputHard(movement.title);
                      handleInputChange({ target: { name: 'hard_version', value: movement._id } });
                    }}
                  >
                    <p>{movement.title}</p>
                    {/* <span style={{fontSize:14,marginLeft:10}}>({movement._id})</span> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label className="form-label">Alias (tags)</label>
            <ReactTags
              tags={tags}
              suggestions={suggestions1}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleTagClick={handleTagClick}
              inputFieldPosition="top"
              autocomplete={false}
              allowDragDrop={false}
              allowDeleteFromEmptyInput={false}
              placeholder="Escribi un tag"
              renderSuggestion={({ text }, query) => (
                <div style={{ textDecoration: 'underline' }}>
                  {text} ({query})
                </div>
              )}
              classNames={{
                tagInputField: 'form-control mb-3',
                tag: 'tag',
                remove: 'remove-tag',
                selected: 'selected-tags',
              }}
            />
            {/* <input
              type="search"
              className="form-control"
              placeholder="Escribi un movimiento como variacion"
              name="hard_version"
              value={variationInputHard}
              onChange={(e) => setvariationInputHard(e.target.value)}
              ref={inputVariationHard}
              onFocus={() => setcloseSuggestionsHard(false)}
              onBlur={() => {
                setTimeout(() => {
                  setcloseSuggestionsHard(true);
                }, 200);
              }}
            /> */}
            {suggestionsHard.length > 0 && variationInputHard.length > 0 && !closeSuggestionsHard && (
              <div className="suggestions">
                {suggestionsHard.map((movement: Video) => (
                  <div
                    className="suggestions-item"
                    onClick={() => {
                      setselectedVariationHard(movement);
                      setcloseSuggestionsHard(true);
                      setvariationInputHard(movement.title);
                      handleInputChange({ target: { name: 'hard_version', value: movement._id } });
                    }}
                  >
                    <p>{movement.title}</p>
                    {/* <span style={{fontSize:14,marginLeft:10}}>({movement._id})</span> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label className="form-label">Cargar video</label>
            <input className="form-control" type="file" name="file" onChange={handleFileChange} />
          </div>
        </div>
      </form>
      {isCorrect ? (
        <div className="alert mt-3 alert-success" role="alert">
          <b>Los datos fueron guardados correctamente</b>
        </div>
      ) : loading ? (
        <div className="alert alert-info" role="alert">
          Cargando...
        </div>
      ) : (
        <div className="text-center">
          <Button className="mt-3 btn-bigg btn-green" onClick={handleUploadFiles}>
            Guardar
          </Button>
        </div>
      )}
    </>
  );
};

export default VideosUploadScreen;
