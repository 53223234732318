import React, { useContext } from 'react'; /*useState, */
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import DashboardRoutes from './DashboardRouter';
//import { USER_VIDEOS, PASS_VIDEOS } from '../config/envVariables';
import { PublicRoute } from './PublicRoute';
//import { LoginScreen } from '../components/login/LoginScreen';
import { LoginScreen } from '../screens';
import { PrivateRoute } from './PrivateRoute';
import { AuthContext } from '../auth/AuthContext';

const AppRouter = () => {
  const { user } = useContext(AuthContext);

  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/login" component={LoginScreen} isAuthenticated={user.logged} />

        <PrivateRoute path="/" component={DashboardRoutes} isAuthenticated={user.logged} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
