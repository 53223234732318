import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import { useForm } from '../../hooks/useForm';
import { getOnDemand, updateOnDemand } from '../../helpers';
import { useParams } from 'react-router-dom';
import {
  AWS_ACCESS_KEY,
  
  AWS_SECRET_ACCESS_KEY,
  ONDEMAND,
} from '../../config/envVariables';
import { Video } from '../../interfaces';
import  {bucketName,REGION_AWS} from '../../services/endpoints'

const VideosEditOnDemandScreen = () => {
  const { onDemandId }: { onDemandId: string } = useParams();
  const [formValues, handleInputChange, resetForm] = useForm({
    title: '',
    date: '',
    instructor: '',
    url: '',
    sede: '',
  });
  const [onDemandID] = useState(onDemandId);
  const [dataMovement, setDataMovement] = useState<Video>();
  const [file /*, setFile*/] = useState<File>();
  const [isCorrect, setIsCorrect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [textError, setTextError] = useState('');
  const { title, date, instructor, url, sede } = formValues;

  const isFormCompleted = () => {
    for (const field in formValues) {
      if (!formValues[field]) {
        return false;
      }
    }
    return true;
  };

  /*
  ///////SE DESCOMENTARA EN CASO QUE SE REQUIERA SUBIR  VIDEO FILES
    const handleFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = target.files;
    if (files) {
      setFile(files[0]);
    }
    setLoading(false);
  };
  */

  const handleEditVideo = async () => {
    setLoading(true);
    setIsError(false);
    if (!isFormCompleted()) {
      setIsError(true);
      setTextError('Hay campos vacío o sin seleccionar');
      return;
    }

    let success;
    if (file) {
      const videoExtension = file.name.split('.').pop();
      const target = {
        Bucket: bucketName,
        Key: `${ONDEMAND}/${dataMovement?._id}.${dataMovement?.video_extension || 'mp4'}`,
        Body: file,
      };
      const credentials = {
        accessKeyId: AWS_ACCESS_KEY || '',
        secretAccessKey: AWS_SECRET_ACCESS_KEY || '',
      };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ credentials, region: REGION_AWS }),
          leavePartsOnError: false,
          params: target,
        });
        const uploadFinished = await parallelUploads3.done();
        if (uploadFinished.$metadata.httpStatusCode !== 200) {
          setIsError(true);
          setTextError('El video no fue actualizado');
        } else {
          ({ success } = await updateOnDemand(onDemandID, {
            ...formValues,
            video_extension: videoExtension,
            video_size: file.size,
          }));
        }
      } catch (error) {
        setIsError(true);
        setTextError('El video no fue actualizado');
        console.error('Error updating video on Bucket', error);
      }
    } else {
      ({ success } = await updateOnDemand(onDemandID, formValues));
    }

    if (success) {
      setIsCorrect(true);
    } else {
      setIsError(true);
      setTextError('Error al guardar el movimiento, comuníquese con el administrador');
    }
    setLoading(false);
  };

  const getMovementEdit = async () => {
    setLoading(true);
    const { success, data } = await getOnDemand(onDemandID);
    if (success && data._id) {
      resetForm({
        title: data.title,
        //video_title: data.video_title,
        date: data.date,
        instructor: data.instructor,
        url: data.url,
        sede: data.sede,
      });

      setDataMovement(data);
    } else {
      setIsError(true);
      setTextError('Error al obtener el movimiento, comuníquese con el administrador');
    }
    setLoading(false);
  };

  useEffect(() => {
    getMovementEdit();
  }, []);

  return (
    <>
      {isError && (
        <div className="alert mt-3 alert-danger" role="alert">
          <b>{textError}</b>
        </div>
      )}
      <h4>Editar video</h4>
      <form>
        <div className="row mb-3">
          <div className="col">
            <label className="form-label">Nombre de la clase</label>
            <input
              type="email"
              className="form-control"
              name="title"
              value={title}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <label className="form-label">URL a video</label>
            <input
              type="email"
              className="form-control"
              name="url"
              value={url}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="row mb-3">
            <div className="col">
              <label htmlFor="start">Fecha</label>
              <br />

              <input
                type="date"
                name="date"
                value={date}
                onChange={handleInputChange}
                min="2018-01-01"
                max="2030-12-31"
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <label className="form-label">Instructor</label>
              <select
                className="form-select"
                name="instructor"
                value={instructor}
                onChange={handleInputChange}
              >
                <option value=""></option>
                <option value="instructor 1">instructor 1</option>
                <option value="instructor 2">instructor 2</option>
                <option value="instructor 3">instructor 3</option>
                <option value="instructor 4">instructor 4</option>
              </select>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <label className="form-label">sede</label>
              <select className="form-select" name="sede" value={sede} onChange={handleInputChange}>
                <option value=""></option>
                <option value="sede 1">sede 1</option>
                <option value="sede 2">sede 2</option>
                <option value="sede 3">sede 3</option>
                <option value="sede 4">sede 4</option>
              </select>
            </div>
          </div>
        </div>
        {/*///////SE DESCOMENTARA EN CASO QUE SE REQUIERA SUBIR  VIDEO FILES
          <div className="row">
          <div className="mb-3">
            <label className="form-label">Reemplazar video por (opcional)</label>
            <input className="form-control" type="file" name="file" onChange={handleFileChange} />
          </div>
        </div>
        */}
      </form>
      {isCorrect ? (
        <div className="alert mt-3 alert-success" role="alert">
          <b>Los datos fueron guardados correctamente</b>
        </div>
      ) : loading ? (
        <div className="alert alert-info" role="alert">
          Cargando...
        </div>
      ) : (
        !isError && (
          <div className="text-center">
            <Button className="mt-3 btn-bigg btn-green" onClick={handleEditVideo}>
              Guardar
            </Button>
          </div>
        )
      )}
    </>
  );
};

export default VideosEditOnDemandScreen;
