import React, { useEffect, useState, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
// import { Upload } from '@aws-sdk/lib-storage';
// import { S3Client } from '@aws-sdk/client-s3';
import { AuthContext } from '../../auth/AuthContext';

import { useForm } from '../../hooks/useForm';
import { getMovement, updateMovement } from '../../helpers';
import { Link, useParams, useLocation } from 'react-router-dom';
// import { AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY, FOLDER_VIDEOS } from '../../config/envVariables';
// import { bucketName, REGION_AWS,  } from '../../services/endpoints';
import { WithContext as ReactTags } from 'react-tag-input';

import { Video, Tag } from '../../interfaces';
import { fetchMovements, handleWithFileUpload, updateTags, updateVersion } from '../../helpers/handleMovements';
interface LocationState {
  page: string;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const VideosEditScreen = () => {
  const { user } = useContext(AuthContext);
  const { movementId }: { movementId: string } = useParams();

  const suggestions1 = user.tags.map((tag: { id: any; title: any }) => {
    return {
      id: tag.id,
      text: tag.title,
    };
  });

  const [formValues, handleInputChange, resetForm, handleCheckbox] = useForm({
    title: '',
    category: [],
    estimulo: [],
    equipamiento: [],
    position: [],
    trainings: [],
    plans: [],
    easy_version: null,
    hard_version: null,
    tags: [],
  });

  const location = useLocation<LocationState>();
  const [movementID] = useState(movementId);
  const [dataMovement, setDataMovement] = useState<Video>();
  const [file, setFile] = useState<File>();
  const [isCorrect, setIsCorrect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [textError, setTextError] = useState('');
  const [suggestions, setsuggestions] = useState([]);
  const [selectedVariation, setselectedVariation] = useState<Video>();
  const [selectedVariationHard, setselectedVariationHard] = useState<Video>();
  const [variationInputHard, setvariationInputHard] = useState('');
  const [suggestionsHard, setsuggestionsHard] = useState([]);
  const [closeSuggestionsHard, setcloseSuggestionsHard] = useState(true);

  const { title } = formValues; //category  //estimulo
  const [variationInput, setvariationInput] = useState('');
  const [closeSuggestions, setcloseSuggestions] = useState(true);
  const inputVariation = useRef<HTMLInputElement>(null);
  const inputVariationHard = useRef<HTMLInputElement>(null);
  const [navigationPage, setnavigationPage] = useState(1);
  const [tags, setTags] = React.useState<Tag[]>([]);
  const isFormCompleted = () => {
    for (const field in formValues) {
      if (!formValues[field] && field !== 'easy_version' && field !== 'hard_version') {
        return false;
      }
    }
    return true;
  };


  useEffect(() => {
    if (typeof variationInput == 'string' && variationInput.length > 2) {
      fetchMovements(variationInput).then((movements) => {
        setsuggestions(movements);
      });
    }
    if (variationInput.length == 0) {
      handleInputChange({ target: { name: 'easy_version', value: null } });
    }
  }, [variationInput]);

  useEffect(() => {
    if (typeof variationInputHard == 'string' && variationInputHard.length > 2) {
      fetchMovements(variationInputHard).then((movements) => {
        setsuggestionsHard(movements);
      });
    }
    if (variationInputHard.length == 0) {
      handleInputChange({ target: { name: 'hard_version', value: null } });
    }
  }, [variationInputHard]);

  const handleFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = target.files;
    if (files) {
      setFile(files[0]);
    }
    setLoading(false);
  };

  const handleEditVideo = async () => {
    setLoading(true);
    setIsError(false);
    if (!isFormCompleted()) {
      setIsError(true);
      setTextError('Hay campos vacío o sin seleccionar');
      return;
    }
    let updatedTags = await updateTags(tags);

    console.log('file', file);


    let success;
    if (file) {
      // const videoExtension = file.name.split('.').pop();
      try {
        console.log('varia', variationInput);
        console.log('variationInputHard', variationInputHard);

        success = await handleWithFileUpload(file, dataMovement, updatedTags, formValues)
        await updateVersion(
          selectedVariation?._id,
          selectedVariationHard?._id,
          movementID,
          selectedVariation && selectedVariationHard ? 'all' : selectedVariation ? 'hard' : 'easy',
          variationInput,
          variationInputHard,
        );
      } catch (error) {
        setIsError(true);
        setTextError('El video no fue actualizado');
        console.error('Error updating video on Bucket', error);
      }
    } else {
      success = await updateMovement(movementID, {
        ...formValues,
        tags: updatedTags.map((tag: { _id: any; title: any }) => ({
          _id: tag._id,
          title: tag.title,
        })),
      });
    }
    await updateVersion(
      selectedVariation?._id,
      selectedVariationHard?._id,
      movementID,
      selectedVariation && selectedVariationHard ? 'all' : selectedVariation ? 'hard' : 'easy',
      variationInput,
      variationInputHard,
    );
    if (success) {
      setIsCorrect(true);
    } else {
      setIsError(true);
      setTextError('Error al guardar el movimiento, comuníquese con el administrador');
    }
    setLoading(false);
  };
  useEffect(() => {
    if (isCorrect) {
      setTimeout(() => {
        setIsCorrect(false)
      }, 2000);
    }
  }, [isCorrect])

  const getMovementEdit = async () => {
    setLoading(true);
    const { success, data } = await getMovement(movementID);

    if (success && data._id) {


      resetForm({
        title: data.title,
        category: Array.isArray(data.category) ? data.category : [data.category],
        estimulo: Array.isArray(data.estimulo) ? data.estimulo : [data.estimulo],
        equipamiento: data.equipamiento,
        trainings: data.trainings ? data.trainings : [],
        plans: data.plans ? data.plans : [],
        position: data.position ? data.position : [],
        easy_version: data.easy_version
          ? data.easy_version?._id
            ? data.easy_version._id
            : null
          : null,
        hard_version: data.hard_version
          ? data.hard_version?._id
            ? data.hard_version._id
            : null
          : null,
        tags:
          data.tags.length > 0
            ? data.tags.map((tag: { _id: any; title: any }) => ({ id: tag._id, text: tag.title }))
            : [],
      });
      if (data.tags.length > 0) {
        setTags(
          data.tags.map((tag: { _id: any; title: any }) => ({ id: tag._id, text: tag.title })),
        );
      }
      if (data.easy_version) {
        setselectedVariation(data.easy_version);
        setvariationInput(data.easy_version.title);
      }
      if (data.hard_version) {
        setselectedVariationHard(data.hard_version);
        setvariationInputHard(data.hard_version.title);
      }
      setDataMovement(data);
    } else {
      setIsError(true);
      setTextError('Error al obtener el movimiento, comuníquese con el administrador');
    }
    setLoading(false);
  };

  useEffect(() => {
    getMovementEdit();
    const query = new URLSearchParams(location.search);
    if (query.get('page')) {
      let pageNumber = query.get('page');
      if (pageNumber) {
        console.log('num', parseInt(pageNumber));
        setnavigationPage(parseInt(pageNumber));
      }
    }
  }, []);
  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: { id: string; text: string }) => {
    if (tags) {
      setTags([...tags, tag]);
    }
    // }
  };

  const handleTagClick = (index: number) => {
    console.log('The tag at index ' + index + ' was clicked');
  };
  console.log('formValues.trainings', formValues.trainings);
  console.log('user.trainings', user.trainings);
  return (
    <>
      {isError && (
        <div className="alert mt-3 alert-danger" role="alert">
          <b>{textError}</b>
        </div>
      )}
      <Link to={{ pathname: '/lista', state: { page: navigationPage } }}>Volver atras</Link>
      <h3>Editar Movimiento</h3>
      <form>
        <div className="row mb-4 mt-4">
          <div className="col">
            <label className="form-label text-bold">Nombre del Movimiento</label>
            <input
              type="email"
              className="form-control"
              name="title"
              value={title}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col">
            <label className="form-label text-bold">Categoría</label>
            {['HOME', 'EQUIPPED', 'OUTDOOR', 'GYM'].map((category, i) => (
              <div key={i}>
                <input
                  // className="form-select"
                  style={{ marginRight: 10 }}
                  name="category"
                  checked={formValues.category.find((value: string) => value == category)}
                  value={category}
                  onChange={(e) => handleCheckbox({ target: { name: e.target.name }, value: e.target.value })}
                  type={'checkbox'}
                />
                {category}
              </div>
            ))}
          </div>

          <div className="col ">
            <label className="form-label text-bold">Estimulos</label>
            <div className="trainings-container">
              {user.trainings.map((estimulo: { title: string; id: string }) => (
                <div className="training-col" key={estimulo.id}>
                  <input
                    // className="form-select"
                    style={{ marginRight: 10 }}
                    checked={formValues.trainings.find((value: { id: string; title: string }) => value.id == estimulo.id)}
                    name="trainings"
                    value={estimulo.id}
                    onChange={(e) => {

                      handleCheckbox({ target: { name: e.target.name }, value: { id: estimulo.id, title: estimulo.title } })
                    }}

                    type={'checkbox'}
                  />
                  {estimulo.title}
                </div>
              ))}
            </div>
          </div>
          <div className="col">
            <label className="form-label text-bold">Equipamiento</label>
            <div className="column-list">
              {user.resultEquipment.map((equipamiento: { title: string; _id: string }) => (
                <div className="" key={equipamiento._id}>
                  <input
                    // className="form-select"
                    style={{ marginRight: 10 }}
                    name="equipamiento"
                    value={equipamiento._id}
                    checked={formValues.equipamiento.find(
                      (value: string) => value == equipamiento._id,
                    )}
                    onChange={(e) => handleCheckbox({ target: { name: e.target.name }, value: equipamiento._id })}

                    type={'checkbox'}
                  />
                  {equipamiento.title}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col ">
            <label className="form-label text-bold">Entrenamientos</label>
            <div className="trainings-container goals-container">
              {user.plans.map((plans: { title: string; id: string }) => (
                <div className="goals-col" key={plans.id}>
                  <input
                    // className="form-select"
                    style={{ marginRight: 10 }}
                    checked={formValues.plans.find((value: string) => value == plans.id)}
                    name="plans"
                    value={plans.id}
                    onChange={(e) => handleCheckbox({ target: { name: e.target.name }, value: plans.id })}

                    type={'checkbox'}
                  />
                  {plans.title}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row mb-4 ">

          <div>
            <label className="form-label text-bold mt-4">Tipo</label>
            {['Parado', 'Sentado', 'Acostado'].map((type, i) => (
              <div className="" key={i}>
                <input
                  style={{ marginRight: 10 }}
                  name="position"
                  value={type}
                  checked={formValues.position.find((value: string) => value == type)}
                  onChange={(e) => handleCheckbox({ target: { name: e.target.name }, value: type })}

                  type={'checkbox'}
                />
                {type}
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label className="form-label text-bold">Variación facil</label>
            <input
              type="search"
              className="form-control"
              placeholder="Escribi un movimiento como variacion"
              name="easy_version"
              value={variationInput}
              onChange={(e) => setvariationInput(e.target.value)}
              ref={inputVariation}
              onFocus={() => setcloseSuggestions(false)}
              onBlur={() => {
                setTimeout(() => {
                  setcloseSuggestions(true);
                }, 200);
              }}
            // onChange={handleInputChange}
            />
            {suggestions.length > 0 && variationInput.length > 0 && !closeSuggestions && (
              <div className="suggestions">
                {suggestions.map((movement: Video) => (
                  <div
                    key={movement._id}
                    className="suggestions-item"
                    onClick={() => {
                      setselectedVariation(movement);
                      setcloseSuggestions(true);
                      setvariationInput(movement.title);
                      handleInputChange({ target: { name: 'easy_version', value: movement._id } });
                    }}
                  >
                    <p>{movement.title}</p>
                    {/* <span style={{fontSize:14,marginLeft:10}}>({movement._id})</span> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="mb-3">
            <label className="form-label text-bold">Variación dificil</label>
            <input
              type="search"
              className="form-control"
              placeholder="Escribi un movimiento como variacion"
              name="hard_version"
              value={variationInputHard}
              onChange={(e) => setvariationInputHard(e.target.value)}
              ref={inputVariationHard}
              onFocus={() => setcloseSuggestionsHard(false)}
              onBlur={() => {
                setTimeout(() => {
                  setcloseSuggestionsHard(true);
                }, 200);
              }}
            />
            {suggestionsHard.length > 0 && variationInputHard.length > 0 && !closeSuggestionsHard && (
              <div className="suggestions">
                {suggestionsHard.map((movement: Video) => (
                  <div
                    key={movement._id}
                    className="suggestions-item"
                    onClick={() => {
                      setselectedVariationHard(movement);
                      setcloseSuggestionsHard(true);
                      setvariationInputHard(movement.title);
                      handleInputChange({ target: { name: 'hard_version', value: movement._id } });
                    }}
                  >
                    <p>{movement.title}</p>
                    {/* <span style={{fontSize:14,marginLeft:10}}>({movement._id})</span> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label className="form-label text-bold">Alias (tags)</label>
            <ReactTags
              tags={tags}
              suggestions={suggestions1}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleTagClick={handleTagClick}
              inputFieldPosition="top"
              autocomplete={false}
              allowDragDrop={false}
              allowDeleteFromEmptyInput={false}
              placeholder="Escribi un tag"
              renderSuggestion={({ text }, query) => (
                <div style={{ textDecoration: 'underline' }}>
                  {text} ({query})
                </div>
              )}
              classNames={{
                tagInputField: 'form-control mb-3',
                tag: 'tag',
                remove: 'remove-tag',
                selected: 'selected-tags',
              }}
            />
            {/* <input
              type="search"
              className="form-control"
              placeholder="Escribi un movimiento como variacion"
              name="hard_version"
              value={variationInputHard}
              onChange={(e) => setvariationInputHard(e.target.value)}
              ref={inputVariationHard}
              onFocus={() => setcloseSuggestionsHard(false)}
              onBlur={() => {
                setTimeout(() => {
                  setcloseSuggestionsHard(true);
                }, 200);
              }}
            /> */}
            {suggestionsHard.length > 0 && variationInputHard.length > 0 && !closeSuggestionsHard && (
              <div className="suggestions">
                {suggestionsHard.map((movement: Video) => (
                  <div
                    key={movement._id}
                    className="suggestions-item"
                    onClick={() => {
                      setselectedVariationHard(movement);
                      setcloseSuggestionsHard(true);
                      setvariationInputHard(movement.title);
                      handleInputChange({ target: { name: 'hard_version', value: movement._id } });
                    }}
                  >
                    <p>{movement.title}</p>
                    {/* <span style={{fontSize:14,marginLeft:10}}>({movement._id})</span> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <label className="form-label">Reemplazar video por (opcional)</label>
            <input className="form-control" type="file" name="file" onChange={handleFileChange} />
          </div>
        </div>
      </form>
      {isCorrect ? (
        <>
          <div className="alert mt-3 alert-success" role="alert">
            <b>Los datos fueron guardados correctamente</b>
          </div>
          <Link to={{ pathname: '/lista', state: { page: navigationPage } }}>Volver atras</Link>
        </>
      ) : loading ? (
        <div className="alert alert-info" role="alert">
          Cargando...
        </div>
      ) : (
        !isError && (
          <div className="text-center">
            <Button className="mt-3 btn-bigg btn-green" onClick={handleEditVideo}>
              Guardar
            </Button>
          </div>
        )
      )}
    </>
  );
};

export default VideosEditScreen;
