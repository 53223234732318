import { ondemandEndpoint } from '../services/endpoints';

export const updateOnDemand = async (idOnDemand, formValues) => {
  try {
    let serviceResponse;

    const response = await fetch(`${ondemandEndpoint}/${idOnDemand}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formValues),
    });
    const parsedResponse = await response;

    if (response.status === 204) {
      serviceResponse = { success: true, data: parsedResponse };
    } else {
      serviceResponse = { success: false, data: parsedResponse };
    }

    return serviceResponse;
  } catch (error) {
    console.error('Error updating onDemand video: ', error);
    return {
      success: false,
      data: error,
    };
  }
};

export default updateOnDemand;
