import React from 'react';
import DatePicker from 'react-datepicker';
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default function RangePicker({ startDate, endDate, onChange }) {
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(null);

  return (
    <DatePicker
      selectsRange={true}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      isClearable={true}
    />
  );
}
