import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  path: string;
  query: string;
  arrayButtons?: number[];
  currentPage?: number;
  pagesNumber?: number;
}

const Paginator = ({ path, query, arrayButtons = [], currentPage = 1, pagesNumber = 1 }: Props) => {
  const history = useHistory();
  const numberOne = 1;
  const prevPage = currentPage - numberOne;
  const nextPage = currentPage + numberOne;

  const handlePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, goTo: number) => {
    event.preventDefault();
    const search = query ? `&q=${query}` : '';
    history.push(`${path}?page=${goTo}${search}`);
  };

  return (
    <>
      {pagesNumber > numberOne && (
        <ul className="pagination bigg-pagination">
          {prevPage >= numberOne && (
            <li className="page-item">
              <a
                href="/"
                className="page-link"
                onClick={(event) => handlePage(event, currentPage - 1)}
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
          )}
          {!arrayButtons.includes(numberOne) && (
            <>
              <li className="page-item">
                <a
                  href="/"
                  className="page-link extremes"
                  onClick={(event) => handlePage(event, numberOne)}
                >
                  {numberOne}
                </a>
              </li>
              <li className="page-item">
                <span className="page-link page-link-nohover">...</span>
              </li>
            </>
          )}
          {arrayButtons.map((boton) => (
            <li
              key={`link-${boton}`}
              className={`page-item ${boton === currentPage ? 'active' : ''}`}
            >
              <a href="/" className="page-link" onClick={(event) => handlePage(event, boton)}>
                {boton}
              </a>
            </li>
          ))}
          {!arrayButtons.includes(pagesNumber) && (
            <>
              <li className="page-item">
                <span className="page-link page-link-nohover">...</span>
              </li>
              <li className="page-item">
                <a
                  href="/"
                  className="page-link extremes"
                  onClick={(event) => handlePage(event, pagesNumber)}
                >
                  {pagesNumber}
                </a>
              </li>
            </>
          )}
          {nextPage <= pagesNumber && (
            <li className="page-item">
              <a href="/" className="page-link" onClick={(event) => handlePage(event, nextPage)}>
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          )}
        </ul>
      )}
    </>
  );
};

export default React.memo(Paginator);
