import React, { useState, useContext } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import {
  USER_VIDEOS,
  PASS_VIDEOS,
  USER_VIDEOS_SOPORTE,
  PASS_VIDEOS_SOPORTE,
  AWS_ACCESS_KEY
} from '../../config/envVariables';
import '../../styles/LoginScreen.css';
import { programmingEndpoint } from '../../services/endpoints';

export const LoginScreen = ({ history }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(' ');
  const { dispatch } = useContext(AuthContext);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const handleInputChange = (event) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = async () => {
    if (types.logout) {
      const user = credentials.username;
      const password = credentials.password;
      if (
        (user === USER_VIDEOS && password === PASS_VIDEOS) ||
        (user === USER_VIDEOS_SOPORTE && password === PASS_VIDEOS_SOPORTE)
      ) {
        setIsAuthenticated(true);
        let equipment = await fetch(programmingEndpoint + '/equipment');
        let tags = await fetch(programmingEndpoint + '/tags');
        let resultEquipment = await equipment.json();
        let resultags = await tags.json();
        let trainings = await fetch(programmingEndpoint +'/trainings')
        let resulttrainings = await trainings.json()
        let plans = await fetch(`https://biggfriends.com/wp-json/programming/v1/api/plans`)
        let resultplans = await plans.json()
        dispatch({
          type: types.login,
          payload: {
            resultEquipment: resultEquipment,
            tags: resultags,
            user: user,
            plans:Object.values(resultplans).map((plan:any)=>({id:plan.id,title:plan.title,divition:plan.divition})).filter((plan)=>!plan.title.includes('Home Edition')).sort((a:any,b:any)=>a.title > b.title ? 1 : -1),
            trainings:resulttrainings
          },
        });

        history.replace('/');
      } else {
        setIsAuthenticated(false);
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div className="screen">
      <form onSubmit={submitHandler} className="box">
        <svg width="76.927" height="26.523" viewBox="0 0 76.927 26.523">
          <path
            id="Path_38"
            data-name="Path 38"
            d="M45.28,9.459a9.21,9.21,0,0,1,5.379,1.751L54.369,7.5l-21.1,1.334A9.425,9.425,0,0,0,32.6,9.917V8.875L16.427,9.917a.957.957,0,0,1,.167.584v.042c0,.876-.834,1.376-2.585,1.376H10.465V10.251l-7.922.5v3.586l38.776,3.252a4.249,4.249,0,0,1-1.543-3.377V14.17c0-2.752,2.293-4.753,5.5-4.712M23.557,15.5a6.841,6.841,0,0,0-3.711-1.918c1.96-.542,3.377-1.584,3.711-3.169ZM65.377.995A13.527,13.527,0,0,0,54.953,5.5L74.716,3.955a15.564,15.564,0,0,0-9.34-2.96m.792,11.258v4.295h4.169v2a6.366,6.366,0,0,1-2.794.459c-3.419,0-5.8-1.96-5.8-4.795V14.17c0-2.71,2.293-4.712,5.462-4.712A9.21,9.21,0,0,1,72.59,11.21l4.545-4.545c-.292-.208-.584-.375-.876-.584L56.5,7.332a9.428,9.428,0,0,0-2.794,4.92H44.2v4.295h4.169v1.668l29.562,2.46V12.252ZM46.822,20.258a5.539,5.539,0,0,1-2.794.542,5.781,5.781,0,0,1-4.253-1.668L1,15.213v11.8H14.467c4.253,0,7.088-1.376,8.422-3.5v3.5h8.13V19.966c2.043,4.67,6.921,7.547,12.633,7.547a16.905,16.905,0,0,0,10.757-3.669v-1.5a13.882,13.882,0,0,0,11.174,5.128A16.905,16.905,0,0,0,76.342,23.8V22.759l-29.562-3ZM15.677,19.09c0,1.042-.917,1.751-2.668,1.751H8.922V17.256h4.086c1.751,0,2.627.751,2.627,1.751v.083ZM53.619,4.664A15,15,0,0,0,43.445.995a13.26,13.26,0,0,0-11.716,6.3l21.1-1.626Zm-44.7,3h3.5c1.293,0,2.085.334,2.418.917L31.02,7.332V1.5H22.931V6.332A4.877,4.877,0,0,0,21.555,3.7c-1.418-1.418-3.544-2.168-6.921-2.168H1V9.709l7.922-.625Z"
            transform="translate(-1 -0.991)"
            fill="#adff19"
          />
        </svg>

        <input type="text" name="username" placeholder="Username" onChange={handleInputChange} />
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={handleInputChange}
        />
        {!isAuthenticated ? (
          <div className="alert alert-danger m-3" role="alert">
            <p>
              Error al introducir <b>usuario</b> o <b>contraseña</b>.
            </p>
          </div>
        ) : (
          <></>
        )}

        <input type="submit" name="" value="Login" href="#" />
      </form>
    </div>
  );
};

export default LoginScreen;
