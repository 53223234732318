import {
  API_URL_PROD,
  ENV,
  API_URL_STAGING,
  AWS_BUCKET_NAME_PROD,
  AWS_BUCKET_NAME_STAGE,
  API_URL_NODE_PROD,
  API_URL_DEV,
  API_URL_NODE_DEV,
  API_URL_NODE_STAGE,
  AWS_BUCKET_REGION_PROD,
  CDN_VIDEOS_STAGE,
  CDN_VIDEOS,
  AWS_BUCKET_REGION_STAGE
} from '../config/envVariables';

export const videosEndpoint = `${ENV == 'staging' ? API_URL_STAGING : ENV == 'prod' ? API_URL_PROD : API_URL_DEV}/api/videos`;
export const ondemandEndpoint = `${ENV == 'staging' ? API_URL_STAGING : API_URL_PROD}/api/onDemand`;
export const programmingEndpoint = `${ENV == 'staging' ? API_URL_NODE_STAGE :  ENV == 'prod' ? API_URL_NODE_PROD :API_URL_NODE_DEV }`;

export const bucketName = `${
  ENV == 'staging' || ENV == 'dev' ? AWS_BUCKET_NAME_STAGE : AWS_BUCKET_NAME_PROD
}`
export const REGION_AWS = `${
  ENV == 'staging' || ENV == 'dev' ? AWS_BUCKET_REGION_STAGE : AWS_BUCKET_REGION_PROD
}`

export const BUCKET_URL =  `${
  ENV == 'staging' || ENV == 'dev' ?  CDN_VIDEOS_STAGE : CDN_VIDEOS
}`