import { videosEndpoint } from '../services/endpoints';

export const getMovement = async (movementId) => {
  try {
    let serviceResponse;

    const response = await fetch(`${videosEndpoint}/${movementId}`);
    const parsedResponse = await response.json();

    if (response.status === 200) {
      serviceResponse = { success: true, data: parsedResponse };
    } else {
      serviceResponse = { success: false, data: parsedResponse };
    }
    console.log('parsedResponse',parsedResponse);
    return serviceResponse;
  } catch (error) {
    console.error('Error getting movement: ', error);
    return {
      success: false,
      data: error,
    };
  }
};

export default getMovement;
